import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/layout"

export default () => (
  <Layout>
      <Helmet>
          <title>Error 404 - Página no encontrada</title>
          <meta
              name="description"
              content="Disculpe las molestias, por favor navegue nuestro sitio web para encontrar la página que busca."
          />
      </Helmet>

      <div className="container py-5 mb-4 text-center">
          <h1 className="display-404">404</h1>
          <h2 className="h3">Página no encontrada</h2>
          <p className="text-muted">Disculpe las molestias, por favor navegue nuestro sitio web para encontrar la página que busca.
              <Link to="/" className="font-weight-medium">Volver al inicio</Link>
          </p>
      </div>

  </Layout>
)
